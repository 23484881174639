// composables/useElementHover.js

import { ref, onMounted, onUnmounted } from "vue";

export default function useElementHover(className: string) {
  const isHovered = ref(false);

  const updateHoverState = (event, hoverState) => {
    if (event.target.classList.contains(className)) {
      isHovered.value = hoverState;
    }
  };

  const handleMouseEnter = (event) => {
    updateHoverState(event, true);
  };

  const handleMouseLeave = (event) => {
    updateHoverState(event, false);
  };

  const observeDOM = (element) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length || mutation.removedNodes.length) {
          attachListeners();
        }
      });
    });

    observer.observe(element, {
      childList: true,
      subtree: true,
    });

    return observer;
  };

  const attachListeners = () => {
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach((element) => {
      element.removeEventListener("mouseenter", handleMouseEnter);
      element.removeEventListener("mouseleave", handleMouseLeave);
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    });
  };

  let observer;

  onMounted(() => {
    document.addEventListener("mouseover", handleMouseEnter);
    document.addEventListener("mouseout", handleMouseLeave);
    observer = observeDOM(document.body);
    attachListeners();
  });

  onUnmounted(() => {
    document.removeEventListener("mouseover", handleMouseEnter);
    document.removeEventListener("mouseout", handleMouseLeave);
    observer.disconnect();
  });

  return {
    isHovered,
  };
}
